import React from "react";
import styled, { useTheme } from "styled-components";
import Text from "components/common/Text";
import Space from "components/common/Space";
import SocialLinks from "components/common/SocialLinks";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { motion } from "framer-motion";
import LinkText from "components/common/LinkText";

export const query = graphql`
  query {
    file(relativePath: { eq: "profile_picture.png" }) {
      childImageSharp {
        fluid(
          maxWidth: 400
          maxHeight: 400
          fit: COVER
          quality: 100
          cropFocus: NORTH
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const TextContainer = styled.div`
  max-width: 90vw;
  margin: 2rem 2rem;
  margin-top: -5rem;
  line-height: 2;
`;

const ImageContainer = styled.div`
  min-width: 220px;
  max-width: 320px;
  width: 40vw;
  margin: 2rem 4rem;
  margin-bottom: 10rem;
`;

const Image = styled(Img)`
  box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.4);
  border-radius: 50%;
`;

const IntroContainer = styled(motion.div)`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const Line = styled.div`
  border-top: ${(props) =>
    `4px solid ${props.theme.colors.accent.primary[500]}`};
  max-width: 700px;
  margin-bottom: 4px;
`;

const Intro = () => {
  const data = useStaticQuery(query);
  const theme = useTheme();
  const accentColor = theme.colors.accent.primary[500];

  return (
    <IntroContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <ImageContainer>
        <Image
          fluid={data.file.childImageSharp.fluid}
          alt="profile picture"
          fadeIn
        />
        <Space margin="2rem 0 0 0">
          <div>
            <SocialLinks size="30px" />
          </div>
        </Space>
      </ImageContainer>
      <TextContainer>
        <Line />
        <Text size="large">{"Devanshi Goradia"}</Text>
        <Text size="medium">Clinical Psychologist (MPhil)</Text>
        <Text size="small">Expertise in,</Text>
        <Text size="small">
          Behavioural Therapy, Cognitive therapy, Clinical Psychology,
        </Text>
        <Text size="small">
          Psychological Assessment, Rational Emotive Behavioural Therapy
        </Text>
      </TextContainer>
    </IntroContainer>
  );
};

export default Intro;
