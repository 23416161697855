import React from "react";
import styled from "styled-components";
import useExperience from "../../hooks/useExperience";
import Text from "../common/Text";
import ExperienceItem from "./ExperienceItem";

const Container = styled.div`
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
`;

const Experience = () => {
  const experience = useExperience();

  return experience ? (
    <Container>
      <Text size="xlarge">Some of my past work has been with,</Text>
      {experience.map(({ company }, index) => (
        <ExperienceItem
          key={index}
          company={company.name}
          shortInfo={company.shortInfo}
        />
      ))}
    </Container>
  ) : null;
};

export default Experience;
