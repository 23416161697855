import React, { useEffect } from "react";
import Intro from "components/home/Intro";
import Experience from "components/home/Experience";
import Skills from "components/home/Skills";
import { navigate } from "gatsby";

export default function Home() {
  useEffect(() => {
    navigate("/");
  }, []);
  return (
    <React.Fragment>
      <Intro />
      <Experience />
      {/* <Skills /> */}
    </React.Fragment>
  );
}
