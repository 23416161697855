import { graphql, useStaticQuery } from "gatsby";

const EXPERIENCE_QUERY = graphql`
  query {
    result: allMdx(
      filter: { fileAbsolutePath: { regex: "/experience/i" } }
      sort: { fields: frontmatter___position, order: DESC }
    ) {
      experience: nodes {
        company: frontmatter {
          name
          shortInfo
        }
      }
    }
  }
`;

const useExperience = () => {
  const {
    result: { experience },
  } = useStaticQuery(EXPERIENCE_QUERY);
  return experience;
};

export default useExperience;
