import React from "react"
import Text from "../common/Text"
import styled, { useTheme } from "styled-components"
import { Event, track } from "../../analytics"

const skillSet = [
  {
    name: "React Native",
    level: 5,
  },
  {
    name: "GraphQL",
    level: 3,
  },
  {
    name: "Android",
    level: 3,
  },

  {
    name: "Bitrise",
    level: 4,
  },
  {
    name: "iOS",
    level: 3,
  },
  {
    name: "Flow",
    level: 3,
  },
  {
    name: "TypeScript",
    level: 4,
  },

  {
    name: "Firebase",
    level: 5,
  },

  {
    name: "Redux",
    level: 4,
  },
  {
    name: "Appcenter",
    level: 3,
  },
  {
    name: "ReactJS",
    level: 3,
  },
]

const SkillCircle = styled.div`
  height: ${props => `${props.level * 40}px`};
  width: ${props => `${props.level * 40}px`};
  border-radius: ${props => `${props.level * 40}px`};
  background-color: ${props => props.theme.colors.accent.primary[500]};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 200ms ease-in-out;
  z-index: 1;
  border: 0.1px solid rgba(0, 0, 0, 0.1);
  box-shadow: -5px 12px 30px -2px rgba(0, 0, 0, 0.5);
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  animation: ${props =>
    `pulse ${Math.max(2, Math.floor(Math.random() * 11))}s ease 1s infinite`};

  &:hover {
    transform: scale(1.2);
    z-index: 2;
    animation: 0s;
  }
`

const Container = styled.div`
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  margin-top: 20vh;
  margin-bottom: 40px;
`

const SkillContainer = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  width: 30vw;
  min-width: 300px;
  max-width: 800px;
  justify-content: center;
  align-items: flex-end;
  margin-top: 50px;
`

const SkillTitle = styled(Text)`
  margin-right: 2rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
`

const Skills = () => {
  const theme = useTheme()
  return (
    <Container>
      <SkillTitle size="xlarge">Tech stack I've worked with,</SkillTitle>
      <SkillContainer>
        {skillSet.map(({ level, name }, index) => (
          <SkillCircle key={index} level={level} index={index}>
            <Text weight="bold" color={theme.colors.background.primary[500]}>
              {name}
            </Text>
          </SkillCircle>
        ))}
      </SkillContainer>
    </Container>
  )
}

export default Skills
